<template>
  <div class="center-container">
    <v-card class="pa-5" max-width="400">
      
      <!-- Dodaj grafikę logo -->
      <v-card-title class="logo-container">
            <img :src="imgSrc" class="logo-image"/>
      </v-card-title>

      <v-card-title class="justify-center">Login</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="login">
          <v-text-field
            label="Email"
            v-model="email"
            type="email"
            required
          ></v-text-field>
          <v-text-field
            label="Password"
            v-model="password"
            type="password"
            required
          ></v-text-field>
          <v-btn type="submit" color="primary" block>Login</v-btn>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn text @click="$router.push('/register')">Don't have an account? Register</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script setup>
  import imgSrc from '@/assets/logo_small.png';
</script>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      password: ''
    };
  },
  methods: {
    async login() {
      try {
        //const response = await axios.post('https://funzonecontrol.com/api/login.php', {
          const response = await axios.post('./api/login.php', {
          email: this.email,
          password: this.password,
        });
        if (response.data.status === 'success') {
          console.log('Login successful:', response.data.message);
        } else {
          console.error('Login failed:', response.data.message);
        }
      } catch (error) {
        console.error('Login failed:', error);
      }
    },
  },
};
</script>

<style scoped>
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.logo-image {
  max-width: 100%; /* Jeśli chcesz, aby logo było responsywne */
  height: auto;
}
</style>
