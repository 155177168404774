<template>
  <v-app>
    <div class="background">
      <router-view />
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
.background {
  background-image: url('@/assets/background.png');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}
</style>
