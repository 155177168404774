import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/UserLogin.vue';
import Register from '../views/UserRegister.vue';
import HelloWorld from '../components/HelloWorld.vue';

const routes = [{
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
    },
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/welcome',
        name: 'welcome',
        component: HelloWorld // WelcomeComponent // komponent, który zostanie wyrenderowany
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;