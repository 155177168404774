<template>
  <div class="center-container">
    <v-card class="pa-5 custom-card">
       <!-- Dodaj grafikę logo -->
       <v-card-title class="logo-container">
            <img :src="imgSrc" class="logo-image"/>
      </v-card-title>
      <v-card-title class="justify-center">Register</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="register">
          <!-- Wiersz dla pól username -->
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="Username *"
                v-model="username"
                type="text"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="e-mail *"
                v-model="email"
                type="email"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          
          <!-- Wiersz dla pól password -->
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="Password *"
                v-model="password"
                type="password"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Reply password *"
                v-model="replypassword"
                type="password"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          
          <v-btn type="submit" color="primary" block>Register</v-btn>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn text @click="$router.push('/login')">Already have an account? Login</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script setup>
  import imgSrc from '@/assets/logo_small.png';
</script>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      email: '',
      password: '',
      replypassword: '',
    };
  },
  methods: {
    async register() {
      try {
        const response = await axios.post('./api/register.php', {
          user: this.user,
          email: this.email,
          password: this.password,
          replypassword: this.replypassword,
        });
        if (response.data.status === 'success') {
          console.log('Registraation successful:', response.data.message);
          this.$router.push({ name: 'welcome' });
        } else {
          console.error('Registration failed:', response.data.message);
        }
      } catch (error) {
        console.error('Registration failed:', error);
      }
    },
  },
};
</script>

<style scoped>
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}


.custom-card {
  width: 80%; /* lub inna wartość */
  max-width: 800px; /* maksymalna szerokość */
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.logo-image {
  max-width: 100%; /* Jeśli chcesz, aby logo było responsywne */
  height: auto;
}

</style>
